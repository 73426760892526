import React from 'react';
import { Row, Col } from 'antd';
import ProgressBar from '../../Progress';

const SkillsProgress = () => (
  <div>
    <h2 style={{marginBottom:"0px"}}>My Skills</h2>
    <Row gutter={[20, 20]}>
      <Col xs={24} sm={24} md={12}>

        <ProgressBar
          percent={80}
          text="JavaScript"
          skillLevel="Advanced"
        />
        <ProgressBar
          percent={80}
          text="TypeScript"
          skillLevel="Advanced"
        />
        <ProgressBar
          percent={80}
          text="NextJS"
          skillLevel="Advanced"
        />

        <ProgressBar
          percent={80}
          text="React"
          skillLevel="Advanced"
        />
      </Col>
      <Col xs={24} sm={24} md={12}>
      <ProgressBar
          percent={50}
          text="AWS"
          skillLevel="Intermediate"
        />
        <ProgressBar
          percent={50}
          text="Java"
          skillLevel="Intermediate"
        />
       
        <ProgressBar
          percent={50}
          text="C#"
          skillLevel="Intermediate"
        />
        <ProgressBar
          percent={30}
          text="Unity"
          skillLevel="Beginner"
        />
      </Col>
    </Row>
  </div>
);

export default SkillsProgress;
