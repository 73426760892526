import React from 'react';
import { Row, Col } from 'antd';
import AboutTile from '../../AbouTile';
import { stripTags, domHtml } from '../../../utils/stripTags';

import SEO from '../../Seo';

const devTime = new Date().getFullYear() - 2017;

const pageText = {
  paraOne: `My name is <b>Alex</b> and I'm a <b>full stack software developer</b>. 
    I really like to create stuff, be it code, art or some good old fashioned writing. My programming roots are based in <b>C#, Unity game 
    development</b> but my core strengths are <b>TypeScript, JavaScript</b> and <b>ReactJS</b> of which I have a working experience of almost ${devTime} years.
    In these ${devTime} years I have also dabbled in working with cloud infrastructures like <b>AWS</b> and have deployed applications
    keeping scalability in mind. <b>Docker, Kubernetes, GitHub Actions and Jenkins</b> are some of the
    tools I use for <b>CI/CD</b>. In the past I have also worked with <b>Java</b> and <b>SpringBoot</b> but I have since moved away and dedicated all of my time to
    the aforementioned technologies`,
  paraTwo: ` Since I've mentioned that game development was my stepping stone into programming 
    I should probably state that I have some experience with game creation; That involves mostly level design 
    and game mechanics programming. My last developed game is a BCI game called <b>PsyBreaker</b> that 
    I developed as part of my final year university project. If you want to know more about my projects or professional experience
    check out my <a href='/resume'>resume</a>. Thanks :)`,
};

const AboutMe = () => {
  return (
    <>
      <div style={{paddingBottom: "24px"}}>
        <SEO
          title="About"
          description="Hello, my name is Alex Batrinu and this is my blog/personal website!"
          path=""
          keywords={['Alex', 'Batrinu', 'FullStack developer', 'Javascript', 'ReactJS', 'Java', 'Unity', 'Game development']}
        />
        <h2>Hello!</h2>
        <p dangerouslySetInnerHTML={domHtml(pageText.paraOne)} />
        <p dangerouslySetInnerHTML={domHtml(pageText.paraTwo)} />
      </div>
      <Row gutter={[20, 20]} style={{paddingBottom: "48px"}}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="location.png"
            height={60}
            alt="location image"
            textH4="Based in"
            textH3="London, UK"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="graduation.png"
            alt="graduation image"
            textH4="Degree in"
            textH3="Computer Science w/ Digital Media and Games"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="controller.png"
            alt="controller image"
            textH4="Interest in"
            textH3="Software and video games development"
          />
        </Col>
      </Row>
    </>
  );
};
export default AboutMe;
