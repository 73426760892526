import React from 'react';
import { Progress } from 'antd';

const ProgressBar = (props) => {
  const { text, percent, skillLevel } = props;
  return (
    <div style={{ marginTop: '20px' }}>
      <div>
        <Progress style={{ width:"90%" }}
          percent={percent || 0}
          strokeWidth={22}
          trailColor="#74B391"
          strokeColor="#2F6A45"
          format={() => `${skillLevel}`}
        />
      </div>
      <div style={{
        position: 'absolute',
        marginTop: '-22px',
        marginLeft: '15px',
        color: 'white',
        fontSize: '13px',
      }}
      >
        <span>{ text || '' }</span>
      </div>
    </div>
  );
};

export default ProgressBar;
